<template>
  <div>
    <DetailsPageTitle :title="`Reports`" :details-id="id">
      <VerificationDropdown
        :details="details"
        @change="$emit('change', $event)"
      />
    </DetailsPageTitle>

    <AdminRemarks :details="details" />

    <div class="grid py-5 lg:space-x-4 lg:grid-cols-2">
      <PaymentInfoBlock :details="details" />
      <ReporterUserAltBlock :details="details" />
    </div>

    <div
      :key="`refresh-${renderComponent}`"
      class="relative mt-2 bg-white rounded shadow"
    >
      <section v-if="!isLoaded" class="h-96">
        <loading :active="!isLoaded" :is-full-page="false" />
      </section>

      <section class="w-full bg-white" v-else>
        <TimelineGroup
          :logs="logs"
          timeline-type="report"
          :is-points-show="false"
        />
        <infinite-loading @infinite="fetchLogs" />
      </section>
    </div>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import { EventBus } from '@/utils/EventBus'
export default {
  name: 'ReportForPaymentScreen',
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  components: {
    AdminRemarks: () => import('./blocks/AdminRemarksBlock'),
    ReporterUserAltBlock: () => import('./blocks/ReporterUserAltBlock'),
    PaymentInfoBlock: () => import('./blocks/PaymentInfoBlock'),
    VerificationDropdown: () => import('./blocks/VerificationDropdownBlock'),
    DetailsPageTitle: () => import('@/components/ui/DetailsPageTitle'),
    TimelineGroup: () => import('@/components/timeline/TimelineGroup.vue'),
    InfiniteLoading: () => import('vue-infinite-loading'),
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getLimit() {
      return 30
    },
    getOffset() {
      return this.logs.length
    },
    getEndpoint() {
      return (
        useEndpoints.report.logs(this.id) +
        `?limit=${this.getLimit}&offset=${this.getOffset}`
      )
    },
  },
  async created() {
    await this.fetchLogs()
  },
  data() {
    return {
      renderComponent: true,
      isLoaded: false,
      logs: [],
    }
  },
  async mounted() {
    EventBus.$on('refresh-report-logs', () => {
      this.logs = []
      this.renderComponent = !this.renderComponent
      this.isLoaded = false
      this.fetchLogs()
    })
  },
  methods: {
    async fetchLogs($state = null) {
      await this.$http
        .get(this.getEndpoint)
        .then((res) => {
          //   console.log('res = ', res.data)
          if (this.logs.length) {
            this.logs = this.logs.concat(res.data?.data)
            // console.log('Loaded later', res.data?.data.length)
          } else {
            this.logs = res.data?.data
            // console.log('Loaded logs', this.logs.length)
            if (!this.isLoaded) this.isLoaded = true
          }

          if ($state) {
            if (res?.data?.meta?.count?.total > this.logs.length) {
              $state.loaded()
            } else {
              $state.complete()
            }
          }
        })
        .catch((err) => {
          console.log('err = ', err.response.data)
          this.isLoaded = true
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
